import Glider from "react-glider";
import FaChevronLeft from "../SVG/Fontawesome/FaChevronLeft";
import FaChevronRight from "../SVG/Fontawesome/FaChevronRight";
import { IStaticCarouselItem } from "./interfaces";
import GliderCarouselItem from "./StaticCarouselItem";
import "./carousel.css";

const GliderContainer = (props: {
  children: JSX.Element
}) => {
  return <div className="glider-container relative">
    {props.children}
  </div>
}

const StaticCarousel = (props: {
  showArrows?: boolean;
  items: IStaticCarouselItem[];
}) => {
  const items = props.items;
  const showArrows = props.showArrows ?? true;
  
  return <Glider
    containerElement={GliderContainer}
    hasDots
    hasArrows={showArrows}
    slidesToShow={1}
    scrollLock
    iconLeft={<FaChevronLeft className="absolute -right-14 sm:-right-20" />}
    iconRight={<FaChevronRight className="absolute -left-14 sm:-left-20" />}
    duration={items.length > 8 ? 0.1 : 0.5}
  >
    {
      items.map((item, index) => (
        <GliderCarouselItem key={index.toString()} item={item} />
      ))
    }
  </Glider>
}

export default StaticCarousel;