import AccordionItem from "./AccordionItem";
import { IAccordion } from "./interfaces";

const Accordion = (props: IAccordion) => {

  if((!props.items)) return <></>;

  return <div className={`space-y-2 ${props.parentClassName ?? ""}`}>
    {
      (props.items).map((item, index) => (
        <AccordionItem parentClassName={props.parentClassName} key={index.toString()} {...item} />
      ))
    }
  </div>
}

export default Accordion;