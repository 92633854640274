import LinkWrapper from "../LinkWrapper";
import PlayIcon from "../SVG/PlayIcon";
import Shimmer from "@/utils/shimmer";
import Image from "next/image";
import { IStaticCarouselItem } from "./interfaces";
import { SyntheticEvent } from "react";
import { updateVideoModalIntent } from "@/store/slices/video";
import { useDispatch } from "react-redux";

const StaticCarouselItem = (props: {
  item: IStaticCarouselItem
}) => {
  const item = props.item;
  const dispatch = useDispatch();


  const handleClick = (e: SyntheticEvent) => {
    // if(item.href) return;
    e.preventDefault();
    dispatch(updateVideoModalIntent({ question_id: item.data.question_id}))
  }

  switch (item.type) {
    case "course_video":
      return (
        <div className="relative aspect-video !left-0">
          <Image
            className="!my-0 w-full h-full"
            src={item.data.image_bg_card ?? ""}
            alt="demo video background"
            width={1000}
            height={1000}
            placeholder="blur"
            blurDataURL={Shimmer(1000, 1000)}
          />

          <button className="flex items-center absolute top-0 left-0 h-full w-full text-white text-left" onClick={handleClick}>
            <div className="flex items-center absolute top-0 left-0 h-full w-full text-white text-left">
              <PlayIcon className="w-10 sm:w-16 absolute-center opacity-80" />

              <div className="p-4 basis-3/5">
                <h2 className="text-sm text-white font-bold lg:text-xl tracking-wider max-three-lines">
                  {item.data.title1}
                </h2>
                <span className="text-xs sm:text-base">{item.data.title2}</span>
              </div>

              <div className="flex justify-center items-end h-full absolute -right-[10%]">
                <Image
                  src={item.data.image_url ?? ""}
                  width={300}
                  height={300}
                  alt="teacher potrait"
                  placeholder="blur"
                  blurDataURL={Shimmer(300, 300)}
                  className="my-0 h-4/5 w-auto"
                />
              </div>
            </div>
          </button>
        </div>
      );
    case "widget_child_autoplay":
      return (
        <div className="relative aspect-video !left-0">
          <Image
            className="absolute top-0 left-0 h-full w-full my-0"
            src={item.data.image_bg_card ?? ""}
            alt="demo video background"
            width={1000}
            height={1000}
            placeholder="blur"
            blurDataURL={Shimmer(1000, 1000)}
          />
          <button className="flex items-center absolute top-0 left-0 h-full w-full text-white text-left" onClick={handleClick}>
            <PlayIcon className="w-10 sm:w-16 absolute-center opacity-80" />
          </button>
        </div>
      );
    case "image":
      return (
        <div className={`relative  !left-0 !right-0 bg-black ${item.data.title1 && item.data.title2 ? "bg-black" : ""}`}>
          <Image
            className={`w-full h-full my-0 aspect-video ${item.data.title1 && item.data.title2 ? "opacity-40" : ""}`}
            src={item.data.image_bg_card ?? ""}
            alt="demo video background"
            width={1000}
            height={1000}
            placeholder="blur"
            blurDataURL={Shimmer(1000, 1000)}
          />

          {(item.data.title1 && item.data.title2) &&
            <div className="flex justiy-center items-center absolute top-0 left-0 h-full w-full  text-white text-left">

              <div className="text-center max-w-4xl m-auto">
                <h3 className="text-lg font-bold lg:text-2xl text-white border-b-2 border-primary pb-2 m-0">
                  {item.data.title1}
                </h3>
                <p className="text-lg font-bold lg:text-2xl m-2">{item.data.title2}</p>
              </div>
            </div>}
        </div>
      );
    default:
      return <></>;
  }
}

export default StaticCarouselItem;