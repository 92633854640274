import Accordion from "../Accordion/Accordion";
import ExternalSvgList from "../SVG/ExternalSvg";
import { Tab } from "@headlessui/react";
import { ITabs } from "./interfaces";
import StaticCarousel from "../Carousel/StaticCarousel";

const TabsContent = (props: ITabs) => {
  switch (props.contentType) {
    case "svg":
      return <ExternalSvgList items={props.items} />
    case "video":
      return <StaticCarousel items={props.items} />
    default:
      return <Accordion parentClassName="accordion" items={props.items} />
  }
};

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
}

const Tabs = (props: {
  data: ITabs;
}) => {
  const {
    contentType,
    ...categories
  } = props.data;
  
  return (
    <div className="w-full">
      <Tab.Group>
        <Tab.List className="max-w-max overflow-x-scroll scroll flex gap-2 p-2">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "shrink-0 mrounded-lg px-4 sm:px-8 p-2.5 text-sm font-medium leading-5 hover:text-accent",
                  "focus-visible:outline-none",
                  selected
                    ? "border-b-2 border-accent text-accent hover:border-accent"
                    : ""
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="py-2">
          {Object.values(categories).map((items, idx) => (
            <Tab.Panel
              key={idx}
              className={({ selected }) =>
                classNames(
                  "rounded-xl",
                  selected
                    ? "visible  opacity-100"
                    : "invisible h-0 opacity-0 pointer-events-none"
                )
              }
              static
            >
              <TabsContent contentType={contentType} items={items} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default Tabs;